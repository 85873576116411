import React from 'react'
import aboutData from "data/sections/about-custom.json";


const PagesHeader = () => {
  return (
    <header className="pages-header circle-bg valign">
      <div className="container">
        <div className="row justify-content-center mt-100">
          <div className="col-lg-10">
            <div className="cont about-header-custom text-center">
              <p class="title-slide fw-500 pt-100 about-heading">{"We Transform Your"}  <span className="title-slide-sec">{"brand's potential"}</span></p >
              <p class="title-slide fw-500 about-heading">{"into"}  <span className="title-slide-sec">{"powerful results"}</span></p >
            </div>
          </div>
          <div className="col-lg-10">
            <div className="img custom-about-img">
              <img src="/img/slid/about_header.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="half sub-bg">
        {/* <div className="circle-color">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div> */}
        <div
          className="bg-img valign about-bg-img"
          style={{ backgroundImage: `url(${aboutData.image})` }}
          // data-overlay-dark="6"
        >
        </div>
      </div>
    </header>
  );
};

export default PagesHeader;